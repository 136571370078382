<template>
  <div>
    <small class="d-block mb-1 d-flex align-items-center">
      <b-icon class="h4 mr-2" icon="exclamation-circle"></b-icon>
      Ao responder sim para uma ou ambos as perguntas, anexar em formado PDF, documentos de evidências referente aos 5
      (cinco) colaboradores cadastrados em “Colaboradores”. Num único documento colocar: cópia do Holerite, folha de ponto
      assinada (física ou digitalmente), comprovante de pagamento e Convenção Coletiva da Categoria.
    </small>
    <div class="d-md-flex gap-4">
      <b-list-group-item class="mb-3 px-0">
        <b-card no-body class="pt-3 px-3">
          <span>
            Em qual dia útil do mês a empresa realiza pagamento dos colaboradores?
          </span>
          <cards-list grid="6" responsive="4" class="mt-2">
            <b-button size="sm" style="height:60px !important" :variant="i + 1 === payment_day ? 'success' : 'light'"
              class=" w-100 h-100 d-flex align-items-center justify-content-center"
              @click="handleChangePayment('payment_day', i + 1)" :value="i + 1"
              v-for="(i, index) in Array.from(Array(30).keys())" :key="index">
              <h6 class="m-0">{{ i + 1 }}°</h6>
            </b-button>
          </cards-list>
        </b-card>
      </b-list-group-item>
      <section>
        <b-list-group-item class="mb-3 px-md-0">
          <span>
            As Horas Extraordinárias realizadas são pagas corretamente na Folha?
          </span>
          <div class="mt-2">
            <b-button size="sm" @click="handleChangePayment('overtime_hours_paid_correctly', true)"
              :variant="overtime_hours_paid_correctly ? 'secondary' : 'light'">
              Sim
            </b-button>
            <b-button size="sm" @click="handleChangePayment('overtime_hours_paid_correctly', false)"
              :variant="!overtime_hours_paid_correctly ? 'secondary' : 'light'" class="ml-2">
              Não
            </b-button>
          </div>
        </b-list-group-item>
        <b-list-group-item class="mb-3 px-md-0">
          <span>
            As Horas Extraordinárias realizadas são pagas com adicioanis legais e
            previsto em Convênção Coletiva correntamente?
          </span>
          <div class="mt-2">
            <b-button size="sm" @click="
              handleChangePayment(
                'overtime_hours_paid_with_legal_additions',
                true
              )
              " :variant="overtime_hours_paid_with_legal_additions ? 'secondary' : 'light'
    ">
              Sim
            </b-button>
            <b-button size="sm" @click="
              handleChangePayment(
                'overtime_hours_paid_with_legal_additions',
                false
              )
              " :variant="!overtime_hours_paid_with_legal_additions ? 'secondary' : 'light'
    " class="ml-2">
              Não
            </b-button>
          </div>
        </b-list-group-item>
        <b-col v-if="overtime_hours_paid_correctly || overtime_hours_paid_with_legal_additions" class="px-0" md="4">
          <b-button size="sm" :variant="Object.keys(proof_file).length > 0 ? 'primary' : 'info'" @click="handleAddProof()"
            target="_blank" class="w-100">
            <div v-if="Object.keys(proof_file).length > 0">
              <b-icon icon="patch-check" class="mr-2" />
              <span class="text-nowrap">Certificado Anexado</span>
            </div>
            <div v-else>
              <b-icon icon="paperclip" />
              <span class="text-nowrap">Anexar Comprovante</span>
            </div>
          </b-button>
        </b-col>
      </section>
      <b-modal id="add-proof-payment" size="md" hide-footer centered title="Anexar Comprovante">
        <b-card no-body class="p-2" v-if="Object.keys(this.proof_file).length > 0">
          <span class="d-block mb-2">
            comprovante anexado em
            {{ proof_file.created_at | dateFull }}
          </span>
          <a class="text-left d-flex align-items-center line-height-1" v-bind:href="proof_file.file" target="_blank"
            download>
            <b-icon icon="paperclip" class="mr-2 m-0" />
            <span> {{ proof_file.name }}</span>
          </a>
        </b-card>

        <file-upload @upload="handleUploadProof" />
      </b-modal>
    </div>
  </div>
</template>
<script>
import { mapActions } from "vuex";
import FileUpload from "@/components/FileUpload";

export default {
  components: {
    FileUpload
  },

  data() {
    return {
      payment_day: 2,
      overtime_hours_paid_correctly: false,
      overtime_hours_paid_with_legal_additions: false,
      proof_file: {},
      payments: [
        {
          name:
            "As Horas Extraordinárias realizadas são pagas corretamente na Folha?",
          value: "overtime_hours_paid_correctly",
        },
        {
          name:
            "As Horas Extraordinárias realizadas são pagas com adicioanis legais e previsto em Convênção Coletiva correntamente?",
          value: "overtime_hours_paid_with_legal_additions",
        },
      ],
    };
  },

  methods: {
    ...mapActions(["edit_payments", "get_all_payments"]),

    handleChangePayment(variable, value) {
      this[variable] = value;
      let data = {
        payment_day: this.payment_day,
        overtime_hours_paid_correctly: this.overtime_hours_paid_correctly,
        overtime_hours_paid_with_legal_additions: this
          .overtime_hours_paid_with_legal_additions
      }
      if (this.overtime_hours_paid_correctly && Object.keys(this.proof_file).length > 0 ||
        this.overtime_hours_paid_with_legal_additions && Object.keys(this.proof_file).length > 0) {
        data['files'] = [this.proof_file.id]
      } else {
        this.proof_file = {};
        data['files'] = []
      }
      this.edit_payments(data);
      this.$forceUpdate();
      this.$bvModal.hide("add-proof-payment");
    },
    handleAddProof() {
      this.$bvModal.show("add-proof-payment");
    },
    handleUploadProof(files) {
      this.handleChangePayment('proof_file', files[0]);
    },
  },
  mounted() {
    this.get_all_payments().then(
      ({
        data: {
          payment_day,
          overtime_hours_paid_correctly,
          overtime_hours_paid_with_legal_additions,
          files
        },
      }) => {
        this.payment_day = payment_day;
        this.overtime_hours_paid_correctly = overtime_hours_paid_correctly;
        this.overtime_hours_paid_with_legal_additions = overtime_hours_paid_with_legal_additions;
        if (files.length > 0) {
          this.proof_file = files[0]
        }
      }
    );
  },
};
</script>
